.PartnerBanner {
  background-color: rgba(217, 40, 60, 0.6);
  background-image: url("../../images/Home/SgHomeBg.gif");
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  margin-top: 56px;
  padding: 3.2rem 0 2.4rem 2.4rem;

  @media (max-width: 330px) {
    padding: 3.2rem 0 2.4rem 0rem;
  }

  &-TextContent {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    @media (max-width: 330px) {
      margin-left: 4px;
    }

    &-Top {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      color: #ffffff;
      width: 130%;

      @media (max-width: 330px) {
        width: 150%;
      }
    }
    &-Bottom {
      margin-top: 16px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      color: #ffffff;
      width: 110%;

      @media (max-width: 330px) {
        width: 120%;
      }
    }
  }
}
