.VideoModal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  &__Container {
    background-color: #ffffff;
    margin: auto;
    border: 1px solid #888;
    width: 100%;
    max-width: 1000px;
    /* height: 80%; */
    /* padding: 20px; */
    border-radius: 1%;
  }

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid #eee;
  }

  &__Header img{
      width: 16px;
      height: 16px;
  }

  &__Body {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
    border-bottom: 1px solid #eee;

    &--Iframe {
      width: 100%;
      height: 200px;
      border: none;
    }
  }
}