.ReferalStatusModal {
  display: flex;
  flex-direction: column;
  background-image: url("../../images/Modal/bgImage.png");
  background-repeat: no-repeat;
  background-position: right bottom;

  &-Header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 24px 24px 24px;
    border-bottom: 1px solid #e5e5e5;

    &-Heading {
      font-style: normal;
      font-weight: 450;
      font-size: 16px;
      line-height: 20px;

      color: rgba(0, 0, 0, 0.87);

      mix-blend-mode: normal;
    }
  }
  &-Content {
    display: flex;
    flex-direction: column;

    &-Item {
      display: flex;
      flex-direction: column;
      margin: 0 24px;
      padding: 24px 0 20px;
      border-bottom: 1px solid #e5e5e5;

      &-Button {
        background: #ffffff;
        border: 1px solid #777777;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        padding: 8px 16px;
        width: 80px;
      }
      &-Text {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 150%;
        margin-top: 12px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  &-Footer {
    display: flex;
    margin: 0px 16px;
    margin-top: 20px;

    &-button {
      background: #2c6a61;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      padding: 12px 0;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      text-align: right;
      cursor: pointer;
      color: #ffffff;
    }
  }
}
