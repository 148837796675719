.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  // margin-top: 6.5rem;

  &-nav {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 16px;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14);
    align-items: center;
    z-index: 1;
    max-width: 1000px;
    margin: auto;

    &-logo {
    }
    &-santa {
      width: 32px;
      height: 32px;
    }
  }
  &-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    background: #f8edcc;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    padding: 20px 24px;
    max-width: 1000px;
    margin: auto;

    &-viewrewards {
      background: #f8edcc;
      border: 1px solid #d9283c;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      width: 50%;
      outline: none;
      // border: none;
      margin-right: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #d9283c;
    }
    &-referfriend {
      background: #d9283c;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      padding: 10px 0;
      color: #ffffff;
      width: 50%;
      outline: none;
      border: none;
      margin-left: 8px;
    }
    &-referfriendOnly {
      background: #d9283c;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      padding: 10px 0;
      color: #ffffff;
      width: 100%;
      outline: none;
      border: none;
      // margin-left: 8px;
    }
  }
}
