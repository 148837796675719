.Carousel {
  background: #f8edcc;
  border-radius: 15px 15px 0px 0px;
  padding: 32px 0 65px 0;
  margin-top: -24px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-Heading {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: #2c6a61;
    padding-left: 24px;
  }
  &-Text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    margin-top: 8px;
    padding-left: 24px;
    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.7);
  }
  &-Content {
    margin-top: 24px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 32px;

    &-Item {
      display: flex;
      justify-content: space-between;
      padding: 12px 0 0 12px;
      position: relative;
      background: #b8d7d2;
      border-radius: 4px;
      width: 80%;
      margin-left: 16px;
      flex-shrink: 0;

      &-TextContent {
        display: flex;
        flex-direction: column;

        &-Quotation {
          width: 25px;
          height: 19px;
        }
        &-Text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.87);
          width: 50%;
        }
        &-Name {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.87);
          margin-top: 12px;
          width: 50%;
        }
        &-Play {
          width: 36px;
          height: 36px;
          margin-top: 14px;
          margin-bottom: 12px;
        }
      }
      &-TeacherImg {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 180px;
        height: 175px;
      }
    }
  }
}
