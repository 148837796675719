.terms-condition {
  padding: 16px;
  flex-direction: column;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  max-width: 1000px;
  margin: auto;
}
.terms-condition-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.terms-condition-content {
  text-align: left;

  /* padding-top:32px; */
}
.terms-condition-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
table {
  width: 100%;
  border: 2px solid #635f5f;
}

.terms-condition-firstPara {
  padding: 24px 0;
  border-bottom: 2px solid #635f5f;
  margin: 12px 0 !important;
  /* margin-top: 12px; */
}

.terms-condition-question {
  font-weight: bolder;
}

.terms-condition-secondPara {
  padding: 24px 0;
  border-top: 2px solid #635f5f;
  border-bottom: 2px solid #635f5f;
  text-align: left;
}

.terms-condition-points {
  display: flex;
  padding: 0 0 0 24px;
}

.logo-image {
  height: 40px;
}

p {
  margin: 12px 0;
  line-height: 19px;
}

@media (max-width: 600px) {
  .terms-condition {
    font-size: 12px;
  }
}
