.ReferWinReward {
  display: flex;
  background: #f8edcc;
  border-radius: 15px 15px 0px 0px;
  flex-direction: column;
  margin-top: -2.4rem;
  padding: 40px 24px 60px 24px;
  width: 100%;

  &-TopCard {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-Contents {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      &-Heading {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 130%;
        color: #2c6a61;
      }
      &-SubText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 120%;
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.7);
      }
      &-Button {
        background: #2c6a61;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 10px 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-top: 16px;
        color: #f8edcc;
        outline: none;
        border: none;
        width: 75%;
      }
    }
  }
  &-MiddleCard {
    margin-top: 24px;
    background: #f8edcc;
    border: 1px solid #db9d20;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px;

    &-TopSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(44, 106, 97, 0.2);

      &-TextContent {
        display: flex;
        flex-direction: column;
        margin-right: 16px;

        &-Heading {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 130%;
          color: #2c6a61;
        }
        &-Text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 120%;
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.6);
        }
        &-Button {
          outline: none;
          border: none;
          margin-top: 16px;
          background: #2c6a61;
          border-radius: 5px;
          padding: 10px 0px;
          display: flex;
          justify-content: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #f8edcc;
          width: 75%;
        }
      }
    }
    &-MiddleSection {
      // display: grid;
      // grid-template-columns: 1fr 1fr 1fr;
      margin-top: 24px;
      // grid-column-gap: 24px;
      display: flex;
      justify-content: space-between;

      &-Item {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-Text {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &-Item img {
        width: 100%;
      }
    }

    &-MiddleSectionNew {
      display: grid;
      grid-template-columns: 2fr 2fr;
      grid-gap: 2rem;
      grid-column-gap: 6rem;
      margin-top: 1rem;
      
      &-Item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-Text {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &-Item img {
        width: 100%;
      }
    }
    &-BottomSection {
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      margin-top: 24px;
      display: flex;
      justify-content: space-around;

      &-Item {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-Text {
          margin-top: 16px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &-Item img {
        width: 100%;
      }
    }
  }
  &-BottomCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    &-TextContent {
      display: flex;
      flex-direction: column;

      &-Heading {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 130%;
        color: #d9283c;
      }
      &-Text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        margin-top: 8px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.7);
      }
      margin-top: 16px;
      &-Button {
        background: #d9283c;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        margin-top: 16px;
        color: #ffffff;
        outline: none;
        border: none;
        width: 75%;
      }
    }
  }
}
