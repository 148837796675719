.YoutubeVideo {
  background: #333333;
  border-radius: 15px 15px 0px 0px;
  margin-top: -24px;
  display: flex;
  flex-direction: column;
  padding: 32px 24px 64px 24px;
  width: 100%;
  &-Heading {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: #f8edcc;
  }
  &-iframe {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    // width: 80%;
    height: 250px;

    &-video {
      width: 100%;
      border: none;
    }
  }
}
