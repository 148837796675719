.CustomUser {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  line-height: 15px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;

  color: #ffffff;
}

@media (max-width: 376px) {
  .CustomUser {
    /* width: 40px;
    height: 40px; */
    border-radius: 50%;
    /* font-size: 13px; */
  }
}
