.MyRewards {
  background: #2c6a61;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: auto;

  &-Header {
    display: flex;
    align-items: center;
    padding: 14px 24px;
    position: fixed;
    top: 0;
    z-index: 1;
    background: #2c6a61;
    width: 100%;

    &-Heading {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      margin-left: 16px;
      color: #ffffff;
    }
  }
}
